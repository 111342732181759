import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import _findIndex from "lodash/findIndex";
import dfIsAfter from "date-fns/isAfter";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button, SmallButton } from "@/components/Buttons";
import Table from "@/components/Table";
import { formatDate, getDateByTimeZone } from "@/lib/date";
import { DeliveryForRiderModal, SetMisclassificationModal } from "@/Modals";
import { QUERY_DATE_TYPE_RECEIPT_DATE } from "@/store/Deliveries";
import {
  QUERY_DATE_TYPE_MISCLASSIFIED_DATE,
  initDate30days,
  initDateTo,
} from "@/store/DeliveriesMisclassification";

import {
  useDeliveriesMisclassificationStore,
  useLoadingStore,
  useModalStore,
} from "@/store/hooks";

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const ButtonsCol = styled(Col)`
  margin-left: -2.5px;
  margin-right: -2.5px;

  button {
    margin-left: 2.5px;
    margin-right: 2.5px;
    margin-bottom: 5px;
  }
`;

const ColFlex = styled(Col)`
  flex: ${({ flex }) => flex || 1};
  padding-left: 5px;
  padding-right: 5px;
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
`;

const DatePickerCol = styled(Col)`
  padding: 0;
  display: flex;
  flex-direction: row;

  input {
    width: calc(50% - 15px);
  }
`;

const DeliveriesTable = styled(Table)`
  font-size: 14px;
`;

const FormLabel = styled(Form.Label)`
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  text-align: right;
  font-size: 14px;
`;

const FormRow = styled(Row)`
  margin-left: -5px;
  margin-right: -5px;
`;

const TableRow = styled(Row)`
  overflow: auto;
  flex: 1;
`;
const TableCol = styled(Col)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Wave = styled.span`
  margin: 0 5px;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 20px);
`;

const ClickableColumn = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const BookIdColumn = ({ delivery }) => {
  const { openModal } = useModalStore();

  function handleClick() {
    openModal(
      <DeliveryForRiderModal
        backdrop
        key="book-detail-modal"
        bookId={delivery.bookId}
        type={delivery.type || "1"}
      />,
    );
  }

  return (
    <ClickableColumn onClick={handleClick}>
      {delivery.renderBookId()}
    </ClickableColumn>
  );
};

const SearchContainer = memo(() => {
  const { state, ...actions } = useDeliveriesMisclassificationStore();

  const { handleSubmit, register, reset, getValues, setValue } = useForm();

  useEffect(() => {
    reset({
      ...state.query,
    });
  }, []);

  const handleChange = useCallback((e) => {
    if (e.target.name === "dateFrom" && e.target.value > getValues().dateTo) {
      setValue("dateTo", e.target.value);
    }

    if (e.target.name === "dateTo" && e.target.value < getValues().dateFrom) {
      setValue("dateFrom", e.target.value);
    }
  }, []);

  const onSubmit = useCallback((data) => {
    actions.setQuery(data);
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormRow>
        <ColFlex flex={2}>
          <FormRow>
            <ColFlex sm={3}>
              <Form.Group>
                <Form.Control as="select" custom name="types" ref={register}>
                  <option value="1;2;3">전체</option>
                  <option value="1">일반</option>
                  <option value="2;3">반품/픽업</option>
                </Form.Control>
              </Form.Group>
            </ColFlex>
            <ColFlex sm={3}>
              <Form.Group>
                <Form.Control as="select" custom name="dateType" ref={register}>
                  <option value={QUERY_DATE_TYPE_MISCLASSIFIED_DATE}>
                    물품확인일자
                  </option>
                  <option value={QUERY_DATE_TYPE_RECEIPT_DATE}>접수일자</option>
                </Form.Control>
              </Form.Group>
            </ColFlex>
            <DatePickerCol sm={6}>
              <Form.Control
                id="start"
                name="dateFrom"
                type="date"
                ref={register}
                onChange={handleChange}
                min={initDate30days}
                max={initDateTo}
              />
              <Wave>~</Wave>
              <Form.Control
                id="end"
                name="dateTo"
                type="date"
                ref={register}
                onChange={handleChange}
                min={initDate30days}
                max={initDateTo}
              />
            </DatePickerCol>
          </FormRow>
        </ColFlex>
        <ColFlex flex={3}>
          <FormRow>
            <ColFlex />
            <ColFlex flex={4}>
              <Row>
                <FormLabel column sm={4}>
                  사측주문번호
                </FormLabel>
                <Col sm={8}>
                  <Form.Control name="orderIdFromCorp" ref={register} />
                </Col>
              </Row>
            </ColFlex>
            <ColFlex flex={4}>
              <Row>
                <FormLabel column sm={4}>
                  예약번호
                </FormLabel>
                <Col sm={8}>
                  <Form.Control name="bookId" ref={register} />
                </Col>
              </Row>
            </ColFlex>
            <ColFlex flex={3}>
              <Row>
                <FormLabel column sm={4}>
                  접수점
                </FormLabel>
                <Col sm={8}>
                  <Form.Control name="spotName" ref={register} />
                </Col>
              </Row>
            </ColFlex>
            <ColFlex flex={2}>
              <Button variant="success" type="submit">
                조회
              </Button>
            </ColFlex>
          </FormRow>
        </ColFlex>
      </FormRow>
    </Form>
  );
});

const DeleteColumn = memo(({ bookId, onAfterUpdate }) => {
  const { ...actions } = useDeliveriesMisclassificationStore();

  const handleClickDelete = useCallback(async () => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      try {
        await actions.setUnmisClassification(bookId);
        onAfterUpdate();
      } catch (e) {
        window.alert(`오분류 삭제에 실패했습니다: ${e.message}`);
      }
    }
  }, [bookId]);

  return (
    <SmallButton variant="danger" onClick={handleClickDelete}>
      삭제
    </SmallButton>
  );
});

export default memo(() => {
  const { state, ...actions } = useDeliveriesMisclassificationStore();
  const { finishLoading, startLoading } = useLoadingStore();
  const { openModal } = useModalStore();

  const [deliveries, setDeliveries] = useState([]);

  const fetch = useCallback(async () => {
    try {
      if (state.query) {
        startLoading();

        await actions.fetchAll({ query: state.query });

        finishLoading();
      }
    } catch (e) {
      window.alert(
        `배송정보를 불러오는데 실패하였습니다.\n에러메시지: ${e.message}`,
      );
    }

    finishLoading();
  }, [actions.fetchAll, state.query]);

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    refineDeliveries();
  }, [
    state.deliveries,
    state.query.bookId,
    state.query.dateType,
    state.query.dateFrom,
    state.query.dateTo,
    state.query.orderIdFromCorp,
    state.query.spotName,
    state.query.types,
  ]);

  useEffect(() => {
    const changedDateFrom = getDateByTimeZone(state.query.dateFrom);
    const changedDateTo = getDateByTimeZone(state.query.dateTo);

    if (dfIsAfter(changedDateFrom, changedDateTo)) {
      actions.setQuery({
        dateFrom: state.query.dateFrom,
        dateTo: state.query.dateTo,
      });
    }
  }, [state.query.dateFrom, state.query.dateTo]);

  const refineDeliveries = () => {
    let _deliveries = [...state.deliveries];

    const types = state.query.types.split(";");
    _deliveries = _deliveries.filter((e) => types.includes(e.type));

    const dateFrom = formatDate(
      new Date(state.query.dateFrom),
      "yyyy-MM-dd 00:00:00",
    );
    const dateTo = formatDate(
      new Date(state.query.dateTo),
      "yyyy-MM-dd 23:59:59",
    );

    if (state.query.dateType === QUERY_DATE_TYPE_MISCLASSIFIED_DATE) {
      _deliveries = _deliveries.filter(
        (e) =>
          new Date(e.misclassifiedAt) >= new Date(dateFrom) &&
          new Date(e.misclassifiedAt) <= new Date(dateTo),
      );
    } else if (state.query.dateType === QUERY_DATE_TYPE_RECEIPT_DATE) {
      _deliveries = _deliveries.filter(
        (e) =>
          new Date(e.receiptDate) >= new Date(dateFrom) &&
          new Date(e.receiptDate) <= new Date(dateTo),
      );
    }

    if (state.query.bookId) {
      _deliveries = _deliveries.filter((e) => e.bookId === state.query.bookId);
    }

    if (state.query.orderIdFromCorp) {
      _deliveries = _deliveries.filter(
        (e) => e.orderIdFromCorp === state.query.orderIdFromCorp,
      );
    }

    if (state.query.spotName) {
      _deliveries = _deliveries.filter(
        (e) => e.spot?.name === state.query.spotName,
      );
    }

    setDeliveries(_deliveries);
  };

  const handleAfterDetailUpdate = useCallback(() => {
    fetch();
  }, [fetch]);

  const handleClickMisclassification = () => {
    openModal(
      <SetMisclassificationModal
        key="set-misclassfication-modal"
        onUpdate={handleAfterDetailUpdate}
      />,
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "예약번호",
        id: "bookId",
        accessor: (row) => <BookIdColumn delivery={row} />,
        selectable: false,
        sortable: true,
        width: 120,
      },
      {
        Header: "접수일시",
        accessor: (row) => row.renderReceiptDate(),
        id: "RECEIPT_DATE",
        sortable: true,
        width: 100,
      },
      {
        Header: "수지",
        accessor: (row) => row.renderPickupDateScheduled("HH:mm"),
        id: "PICKUP_SCHEDULED_DATE",
        sortable: true,
        width: 60,
      },
      {
        Header: "수완",
        accessor: (row) => row.renderPickupDateCompleted("HH:mm"),
        id: "PICKUP_COMPLETED",
        sortable: true,
        width: 60,
      },
      {
        Header: "입고",
        accessor: (row) => row.renderWarehousedAt("HH:mm"),
        id: "WAREHOUSED",
        sortable: true,
        width: 60,
      },
      {
        Header: "배차",
        accessor: (row) => row.renderDeliveryAllocatedDate(),
        id: "DELIVERY_ALLOCATED",
        sortable: true,
        width: 90,
      },
      {
        Header: "연기",
        accessor: (row) => row.renderPostponedDate(),
        id: "DELIVERY_WAITING",
        sortable: true,
        width: 90,
      },
      {
        Header: "배출",
        accessor: (row) => row.renderReleasedAt(),
        id: "DELIVERY_STARTED",
        sortable: true,
        width: 90,
      },
      {
        Header: "배완",
        accessor: (row) => row.renderDeliveryCompletedDate(),
        id: "DELIVERY_COMPLETED",
        sortable: true,
        width: 90,
      },
      {
        Header: "접수점",
        accessor: (row) => row.spotName,
        id: "SPOT_NAME",
        sortable: true,
        width: 150,
      },
      {
        Header: "수거 담당",
        accessor: (row) => row.pickupRiderName,
        id: "PICKUP_RIDER_NAME",
        sortable: true,
        width: 80,
      },
      {
        Header: "배송 담당",
        accessor: (row) => row.deliveryRiderName,
        id: "DELIVERY_RIDER_NAME",
        sortable: true,
        width: 80,
      },
      {
        Header: "삭제",
        accessor: (row) => (
          <DeleteColumn
            bookId={row.bookId}
            onAfterUpdate={handleAfterDetailUpdate}
          />
        ),
        selectable: false,
        width: 100,
      },
    ],
    [handleAfterDetailUpdate],
  );

  return (
    <Wrap>
      <Container>
        <Title>오분류 관리</Title>

        <SearchContainer />

        <Row>
          <ButtonsCol>
            <Button onClick={handleClickMisclassification}>오분류 등록</Button>
          </ButtonsCol>
        </Row>

        <TableRow>
          <TableCol>
            <DeliveriesTable
              deliveries
              responsive
              bordered
              hover
              columns={columns}
              data={deliveries}
              pagination={false}
            />
          </TableCol>
        </TableRow>
      </Container>
    </Wrap>
  );
});
